.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-main {
  background-image: 
    url("assets/istockphoto-183780507-612x612.jpg"), 
    url("assets/concrete-brick-walkway-background_181624-19158.png"),
    url("assets/outdoors-cobblestone-texture_23-2149432990.jpg"), 
    url("assets/istockphoto-1305677355-640x640.jpg");
  background-position: top left, top right, bottom left, bottom right;
  /* Center the image */
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  /* Do not repeat the image */
  background-size: auto, cover;
  background-color: #282c34;
  background-size: 50% 50%, 50% 50%, 50% 50%, 50% 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rajdhani';
  font-size: calc(10px + 2vmin);
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777, 0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333, 0px 8px 7px #001135;
}

.App-title {
  color: #61dbfb;
  font-weight: 500;
  font-size: calc(10px + 4vmin);
}

.App-link {
  color: #61dafb;
  font-weight: 500;
  font-size: calc(10px + 3vmin);
  text-decoration: none;
}

.App-list {
  color:white;
  font-weight: 500;
  font-size: calc(10px + 2vmin);
}

@media (orientation: portrait) {
  .App-main {
    flex-direction: column;
    background-position: top, center left, center right, bottom;
    background-size: 100% 33.3%, 100% 33.3%, 100% 33.3%;
  }
}
